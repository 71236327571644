<template>
    <vx-card title="Preview">
        <iframe sandbox v-bind:src="bodyBlob"></iframe>
    </vx-card>
</template>

<script>
export default {
    name: 'EmailPreviewCard',
    props: ['data'],
    computed: {
        bodyBlob() {
            var blob = new Blob([this.data], { type: 'text/html' })
            return URL.createObjectURL(blob)
        },
    },
}
</script>

<style scoped>
iframe {
    -webkit-appearance: none;
    border-style: none;
    border-image: none;
    border-width: 0;
    width: 100%;
    height: 1350px;
}
</style>
