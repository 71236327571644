<template>
    <SideBarModal
        :title="'Send Test Email ' + '\'' + email.title + '\''"
        :show="show"
        @close="$emit('close')"
    >
        <template v-slot:content>
            <div class="vx-row">
                <div class="vx-col w-full mb-6">
                    <vs-textarea
                        v-model="data.to"
                        type="email"
                        class="w-full"
                        label="To Email Address: Can use ',' or ';' seperated for multiple"
                    />
                </div>
                <div class="vx-col w-full mb-6">
                    <vs-textarea
                        v-model="data.extra"
                        type="number"
                        label="Append Extra Information"
                    />
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button
                class="mr-6 vs-con-loading__container"
                @click="send"
                id="button-with-loading"
                :disabled="sending"
                >Send</vs-button
            >
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'SendTestEmail',
    props: ['email', 'show'],
    components: {
        SideBarModal,
    },
    data() {
        return {
            data: {
                to: '',
                extra: '',
            },
            sending: false,
        }
    },
    methods: {
        send() {
            if (this.data.to) {
                this.$vs.loading({
                    background: this.backgroundLoading,
                    color: this.colorLoading,
                    container: '#button-with-loading',
                    scale: 0.45,
                })
                this.sending = true
                this.data.id = this.email.id
                http.post('send_test_email', this.data)
                    .then((response) => {
                        this.$vs.notify({
                            title: 'Success',
                            text: 'Email sent',
                            color: 'success',
                            iconPack: 'feather',
                            position: 'top-right',
                            icon: 'icon-send',
                        })
                        this.$emit('close')
                        this.$vs.loading.close(
                            '#button-with-loading > .con-vs-loading'
                        )
                        this.sending = false
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Error',
                            text: error.response.data,
                            color: 'danger',
                            iconPack: 'feather',
                            position: 'top-right',
                            icon: 'icon-send',
                        })
                        this.$vs.loading.close(
                            '#button-with-loading > .con-vs-loading'
                        )
                        this.sending = false
                    })
            }
        },
    },
}
</script>

<style></style>
